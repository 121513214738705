import { useState } from "react"
import styled from "styled-components"

import { eventGroups } from "src/data/events/logic/eventConstants"
import { TEventGroupValues } from "src/data/events/types/eventTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DropdownMultiSelectDemo() {
  const { _t } = useTranslate()
  const translatedLabels = eventGroups.map((e) => ({
    ...e,
    label: _t(e.label),
  }))
  const [groupValues, setGroupValues] = useState<TEventGroupValues[]>([])
  const [options, setOptions] = useState(translatedLabels)

  function handleChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<TEventGroupValues>
  }) {
    if (checked) {
      setGroupValues((prev) => {
        return Array.from(new Set([...prev, option.value]))
      })
    } else {
      setGroupValues((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleSearch(searchValue: string) {
    setOptions(() =>
      translatedLabels.filter((option) =>
        option.label
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      )
    )
  }

  function handleClearSelection() {
    setGroupValues([])
  }

  return (
    <div>
      <DropdownMultiSelect
        options={options}
        onSearch={handleSearch}
        selectedValues={groupValues}
        onChange={handleChange}
        placeholder="Search..."
        label="Event type"
        onClearSelection={handleClearSelection}
      />
      <StyledCard>
        <MText variant="subtitle">Selected values: </MText>
        <div>{groupValues.map((v) => `${v}, `)}</div>
      </StyledCard>
    </div>
  )
}

const StyledCard = styled(MCardInteractive)`
  margin-top: ${spacing.L};
`
