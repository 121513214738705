import { DefaultComboboxDemo } from "src/components/Sandbox/ComboboxDemo/DefaultComboboxDemo"
import { MultipleComboboxDemo } from "src/components/Sandbox/ComboboxDemo/MultipleComboboxDemo"
import { RequiredComboboxDemo } from "src/components/Sandbox/ComboboxDemo/RequiredComboboxDemo"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ComboboxDemo() {
  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.M}>
        Default
      </MText>
      <DefaultComboboxDemo />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Required
      </MText>
      <RequiredComboboxDemo />

      <Divider $margin={spacing.XL} />

      <MText variant="heading3" marginBottom={spacing.M}>
        Multiple
      </MText>
      <MultipleComboboxDemo />
    </div>
  )
}
