import { useState } from "react"
import styled from "styled-components"

import { BaseModalDialog } from "src/ui/BaseModalDialog/BaseModalDialog"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BaseModalDialogDemo() {
  return (
    <div>
      <MText variant="heading3">Regular dialog</MText>
      <RegularDialogDemo />
      <br />
      <MText variant="heading3">Nested dialogs</MText>
      <NestedDialogDemo />
    </div>
  )
}

function RegularDialogDemo() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open dialog</button>
      <BaseModalDialog
        open={open}
        onClose={() => setOpen(false)}
        content={
          <DialogContent>
            <p>
              This is a demo of the BaseDialog used by MDialog. The component is
              unstyled, bu this demo has some styling
            </p>
            <p>
              This uses the {"<dialog>"} component and is as basic as possible
            </p>

            <p>You can close the dialog with:</p>
            <ul>
              <li>Escape key</li>
              <li>Close button</li>
              <li>Click outside</li>
            </ul>
            <p>
              You may notice that you can close the dialog when you click close
              to the border, that will not be the case with MDialog {":)"}
            </p>
            <p>
              The reason to that, is the margin the text has and the event
              listener on the dialog element
            </p>

            <button>This should get focus on open</button>
            <br />
            <button onClick={() => setOpen(false)}>Close</button>
            <br />
          </DialogContent>
        }
      />
    </div>
  )
}

function NestedDialogDemo() {
  const [dialogOneOpen, setDialogOneOpen] = useState(false)
  const [dialogTwoOpen, setDialogTwoOpen] = useState(false)
  const [dialoThreeOpen, setDialogThreeOpen] = useState(false)

  return (
    <div>
      <button onClick={() => setDialogOneOpen(true)}>Open dialog one</button>

      <BaseModalDialog
        open={dialogOneOpen}
        onClose={() => setDialogOneOpen(false)}
        content={
          <DialogContent>
            <p>This is a demo of when you nest BaseDialogs</p>
            <button onClick={() => setDialogOneOpen(false)}>Close</button>

            <br />

            <button onClick={() => setDialogTwoOpen(true)}>
              Open dialog two
            </button>
            <BaseModalDialog
              open={dialogTwoOpen}
              onClose={() => setDialogTwoOpen(false)}
              content={
                <DialogContent>
                  <p>This is a dialog two</p>

                  <button onClick={() => setDialogThreeOpen(true)}>
                    Open dialog three
                  </button>
                  <BaseModalDialog
                    open={dialoThreeOpen}
                    onClose={() => setDialogThreeOpen(false)}
                    content={
                      <DialogContent>
                        <p>This is a dialog three</p>
                        <p>Three level nesting should probably never be done</p>
                      </DialogContent>
                    }
                  />
                </DialogContent>
              }
            />
          </DialogContent>
        }
      ></BaseModalDialog>
    </div>
  )
}

const DialogContent = styled.div`
  width: 400px;
  padding: ${spacing.M};
  background-color: ${mColors.neutralLight};
`
