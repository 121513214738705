import { useMemo, useState } from "react"

import { Combobox } from "src/ui/Combobox/Combobox"

const options = [
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Green",
    value: "green",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
  {
    label: "Purple",
    value: "purple",
  },
  {
    label: "Orange",
    value: "orange",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Pink",
    value: "pink",
  },
  {
    label: "Brown",
    value: "brown",
  },
]

export function DefaultComboboxDemo() {
  const [searchedInput, setSearchedInput] = useState("")

  const [selected, setSelected] = useState("red")

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchedInput.toLowerCase())
    )
  }, [searchedInput])

  return (
    <Combobox
      label="Default"
      options={filteredOptions}
      selectedValue={selected}
      onChange={(value) => {
        setSelected(value)
      }}
      onSearch={(input) => {
        setSearchedInput(input)
      }}
    />
  )
}
